<template>
  <div>
    <van-cell-group>
      <van-cell
        title="新增商品"
        value="去新增"
        is-link
        icon="add"
        to="/product/add"
      />
    </van-cell-group>
    <van-cell-group style="margin-top: 0.3rem">
      <van-cell
        title="修改商品"
        value="去修改"
        is-link
        icon="edit"
        to="/product/category"
      />
    </van-cell-group>
  </div>
</template>
<script>
export default {
  name: "management",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped></style>
